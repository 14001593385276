<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios'
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import DatePicker from "vue2-datepicker";
import VueLoading from 'vuejs-loading-plugin'
import Vue from 'vue';
import moment from 'moment'

import simplebar from "simplebar-vue";

// using default options
Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})
// import Swal from "sweetalert2";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Task Management",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, vSelect, DatePicker, simplebar },
  data() {
    return {
      //   tableData: tableData,
      title: "Task Management",
      items: [
        {
          text: "Project",
          href: "/"
        },
        {
          text: "Task",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "nama_task", sortable: true, label: 'Tasks' },
        { key: "bobot", label: 'Weight', sortable: true },
        { key: "anggota", label: 'Members', sortable: true },
        { key: "start", sortable: true, label: 'Timeline/Task Progress' },
        { key: "dependency", sortable: true },
        { key: "filename", sortable: true, label: 'File' },
        'action'
      ],
      baseapi: localStorage.getItem("baseapi"),
      idProyek: localStorage.getItem("idProyek"),
      dataProyek: JSON.parse(localStorage.getItem('proyek')),
      user: JSON.parse(localStorage.getItem('user')),
      dataTask: [],
      dataAbility: [],
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      daterange: "",
      dataUser: [],
      dataMember: [],
      fieldsAnggota: [
        { key: "nama_user", label: "Nama", sortable: true },
        { key: "ability", label: "Ability", sortable: true },
        'aksi'
      ],
      fieldsPosisi: [
        { key: "nama_posisi", label: "Nama", sortable: true },
        { key: "ability", label: "Ability", sortable: true },
        { key: "variable_tampil", label: "Tampil", sortable: true },
        { key: "parent", label: "Parent", sortable: true },
        'aksi'
      ],
      filterAnggota: null,
      filterPosisi: null,
      dataPosisi: [],
      detailAnggota: [],
      checked: true,
      detailTask: [],
      detailTaskOri: [],
      // detailTask:[{
      //   id_proyek: null,
      //   id_penanggungjawab: null,
      //   nama_task: null,
      //   deskripsi_task: null,
      //   start: null,
      //   end: null,
      //   type: null,
      //   id_parent: null,
      //   id_predecessors: null,
      //   bobot: null,
      //   file: null
      // }],
      optionsTask: [
        { value: 'task', text: 'Task' },
        { value: 'parent', text: 'Parent' },
        { value: 'milestone', text: 'Milestone' },
      ],
      id_parent: null,
      id_predecessors: null,
      file: null,
      id_penanggungjawab: null,
      waktu: [],
      bobot: null,
      dataVariable: [],
      detailPosisi: [],
      id_penanggungjawab_tambah: null,
      filterMember: ''
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataTask.length;
    },

    rowsAnggota() {
      return this.dataMember.length;
    },

    rowsPosisi() {
      return this.dataPosisi.length;
    },

    filteredList() {
      if (this.detailTask.length != 0) {
        console.log(this.detailTask.nama_penanggungjawab)
        return this.detailTask.nama_penanggungjawab.filter(data => data.toLowerCase().includes(this.filterMember.toLowerCase()))
      } else {
        return null
      }

      // return this.detailTask.nama_penanggungjawab.filter(post => {
      //   return post.toLowerCase().includes(this.filterMember.toLowerCase())
      // })
    },

  },
  mounted() {
    this.loaddata()
    this.getUser()
    this.getVariable()
    this.getAbility()
    this.getPosisi()
    this.getMember()
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('ddd, DD MMMM YYYY ')
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


    resetModal() {
      this.detailTask = [],
        this.detailAnggota = [],
        this.detailPosisi = [],
        this.waktu = null,
        this.bobot = null,
        this.id_parent = null,
        this.id_predecessors = null
      this.id_penanggungjawab = []
    },

    deleteData(id) {
      axios
        .delete(
          this.baseapi + `api/ability/` + id,
          {
            headers: {
              Authorization: `Bearer ` + this.user.accessToken
            }
          }
        )
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Berhasil Dihapus',
            showConfirmButton: false,
            timer: 1500
          })
          this.loaddata()
          return res;
        })
        .catch((err) => {
          console.log(err)
          Swal.fire({
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 1500
          })
          return err;
        });
    },

    deleteAnggota(id) {
      axios
        .delete(
          this.baseapi + `api/assign_member/` + id,
          {
            headers: {
              Authorization: `Bearer ` + this.user.accessToken
            }
          }
        )
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Berhasil Dihapus',
            showConfirmButton: false,
            timer: 1500
          })
          this.getMember()
          return res;
        })
        .catch((err) => {
          console.log(err)
          Swal.fire({
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 1500
          })
          return err;
        });
    },

    deleteTask(id) {
      Swal.fire({
        title: 'Are you sure to delete this task?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              this.baseapi + `api/task/` + id,
              {
                headers: {
                  Authorization: `Bearer ` + this.user.accessToken
                }
              }
            )
            .then((res) => {
              Swal.fire({
                icon: 'success',
                title: 'Berhasil Dihapus',
                showConfirmButton: false,
                timer: 1500
              })
              this.loaddata()
              return res;
            })
            .catch((err) => {
              console.log(err)
              Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              return err;
            });
        }
      })
    },

    onSubmit() {
      this.$validator.validateAll('form-1').then((result) => {
        if (result) {
          this.$loading(true);
          let formData = new FormData();
          // if (this.waktu == null) {
          //   this.waktu[0] = null
          //   this.waktu[1] = null
          // }
          let data = []
          if (this.id_penanggungjawab != null) {
            data.push(this.id_penanggungjawab)
            console.log(data)
          } else {
            data = null
          }
          formData.append('id_proyek', this.idProyek);
          formData.append('id_penanggungjawab', data);
          formData.append('nama_task', this.detailTask.nama_task);
          formData.append('deskripsi_task', this.detailTask.deskripsi_task);
          if (this.waktu == null) {
            formData.append('start', null);
            formData.append('end', null);
          } else {
            formData.append('start', this.waktu[0]);
            formData.append('end', this.waktu[1]);
          }
          formData.append('type', this.detailTask.type);
          formData.append('id_parent', this.id_parent);
          formData.append('id_predecessors', this.id_predecessors);
          formData.append('bobot', this.bobot);
          formData.append('file', this.file);
          axios.post(this.baseapi + 'api/task', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ` + this.user.accessToken
            }
          })
            .then((res) => {
              this.$loading(false);
              Swal.fire({
                icon: 'success',
                title: 'Berhasil Ditambah',
                showConfirmButton: false,
                timer: 1500
              })
              this.loaddata()
              this.detailTask = []

              // Wait until the models are updated in the UI
              this.$nextTick(() => {
                this.$bvModal.hide('modal-tambah')
              });
              return res;
            })
            .catch((err) => {
              this.$loading(false);
              console.log(err)
              Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              return err;
            });
        }
      })
    },

    tambahAnggota() {
      this.$validator.validateAll('form-1').then((result) => {
        if (result) {
          this.$loading(true);
          let formData = new FormData();

          const checkArray = this.detailTask.id_penanggungjawab.includes(this.id_penanggungjawab_tambah)
          if (this.id_penanggungjawab_tambah != null) {
            if (checkArray == false) {
              this.detailTask.id_penanggungjawab.push(this.id_penanggungjawab_tambah)
              formData.append('id_penanggungjawab', this.detailTask.id_penanggungjawab);

              axios.post(this.baseapi + 'api/task/' + this.detailTask.id, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ` + this.user.accessToken
                }
              })
                .then((res) => {
                  this.$loading(false);
                  Swal.fire({
                    icon: 'success',
                    title: 'Successfully added members',
                    showConfirmButton: false,
                    timer: 1500
                  })

                  axios.get(this.baseapi + "api/task/" + this.detailTask.id, {
                    headers: {
                      Authorization: `Bearer ` + this.user.accessToken
                    }
                  }).then(response => {
                    this.detailTask = response.data.data;
                    this.detailTaskOri = response.data.data;
                  })
                  this.loaddata()
                  return res;
                })
                .catch((err) => {
                  this.$loading(false);
                  console.log(err)
                  Swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  return err;
                });
            } else {
              this.$loading(false);
              this.$bvToast.toast("Member is exist", {
                title: `Error`,
                variant: 'danger',
                solid: true
              });
            }
          } else {
            this.$loading(false);
            this.$bvToast.toast("Please select member", {
              title: `Error`,
              variant: 'danger',
              solid: true
            });
          }

        }
      })
    },

    deleteAnggotaa(data) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          let formData = new FormData();

      let index = this.detailTaskOri.nama_penanggungjawab.indexOf(data)
      console.log(index)
      const x = this.detailTaskOri.id_penanggungjawab.splice(index, 1);
      //delete this.detailTaskOri.id_penanggungjawab[index];
      console.log(x)

      console.log(this.detailTaskOri.id_penanggungjawab)
      formData.append('id_penanggungjawab', this.detailTaskOri.id_penanggungjawab);

      axios.post(this.baseapi + 'api/task/' + this.detailTask.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ` + this.user.accessToken
        }
      })
        .then((res) => {
          this.$loading(false);
          Swal.fire({
            icon: 'success',
            title: 'Successfully delete members',
            showConfirmButton: false,
            timer: 1500
          })

          axios.get(this.baseapi + "api/task/" + this.detailTask.id, {
            headers: {
              Authorization: `Bearer ` + this.user.accessToken
            }
          }).then(response => {
            this.detailTask = response.data.data;
            this.detailTaskOri = response.data.data;
          })
          this.loaddata()
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err)
          Swal.fire({
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 1500
          })
          return err;
        });

        }
      })
      
    },

    editTask() {
      this.$validator.validateAll('form-1').then((result) => {
        if (result) {
          this.$loading(true);
          let formData = new FormData();
          if (this.detailTask.waktu == null) {
            this.detailTask.start = null
            this.detailTask.end = null
          } else {
            this.detailTask.start = this.detailTask.waktu[0]
            this.detailTask.end = this.detailTask.waktu[1]
          }

          if (this.detailTask.id_parent === 0) {
            this.detailTask.id_parent = null
          }

          if (this.detailTask.id_predecessors === 0) {
            this.detailTask.id_predecessors = null
          }
          // let data = []
          // if (this.id_penanggungjawab != null) {
          //   data.push(this.id_penanggungjawab)
          //   console.log(data)
          // } else {
          //   data = null
          // }
          // formData.append('id_proyek', this.idProyek);

          if (this.id_penanggungjawab_tambah != null) {
            this.detailTask.id_penanggungjawab.push(this.id_penanggungjawab_tambah)
          }


          formData.append('id_penanggungjawab', this.detailTask.id_penanggungjawab);
          formData.append('nama_task', this.detailTask.nama_task);
          formData.append('deskripsi_task', this.detailTask.deskripsi_task);
          formData.append('start', this.detailTask.start);
          formData.append('end', this.detailTask.end);
          formData.append('type', this.detailTask.type);
          formData.append('id_parent', this.detailTask.id_parent);
          formData.append('id_predecessors', this.detailTask.id_predecessors);
          formData.append('bobot', this.detailTask.bobot);
          formData.append('file', this.detailTask.file);
          axios.post(this.baseapi + 'api/task/' + this.detailTask.id, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ` + this.user.accessToken
            }
          })
            .then((res) => {
              this.$loading(false);
              Swal.fire({
                icon: 'success',
                title: 'Berhasil Ditambah',
                showConfirmButton: false,
                timer: 1500
              })
              this.loaddata()
              this.detailTask = []

              // Wait until the models are updated in the UI
              this.$nextTick(() => {
                this.$bvModal.hide('modal-edit-task')
                this.$bvModal.hide('modal-edit-task-anggota')
              });
              return res;
            })
            .catch((err) => {
              this.$loading(false);
              console.log(err)
              Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              return err;
            });
        }
      })
    },


    // loaddata user
    loaddata() {
      axios.get(this.baseapi + "api/task?filter=id_proyek,=," + this.idProyek + '&sort=kode_task,ASC', {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        let data = response.data.data;
        if (this.dataProyek.ability.lihat_semua_kegiatan == true) {
          this.dataTask = data
        } else {
          if (this.dataProyek.ability.lihat_kegiatan_bawahan == true) {
            for (let index = 0; index < data.length; index++) {
              if (data[index].id_pembuat == this.user.id) {
                this.dataTask.push(data[index])
              }
            }
          } else {
            for (let index = 0; index < data.length; index++) {
              if (data[index].id_pembuat == this.user.id) {
                this.dataTask.push(data[index])
              }
            }
          }
        }
      }).catch(error => {
        console.log(error)
        return error
      });
    },

    // dataUser
    getUser() {
      axios.get(this.baseapi + "api/user?filter=role,=,user", {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        this.dataUser = response.data.data;
      }).catch(error => {
        console.log(error)
        return error
      });
    },

    // data member
    getMember() {
      axios.get(this.baseapi + "api/assign_member?filter=id_proyek,=," + this.idProyek, {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        this.dataMember = response.data.data;
        // if (data === 'anggota') {
        //     this.$bvModal.show('modal-anggota')
        // } else {
        //     this.detailTask = []
        //     this.$bvModal.show('modal-tambah')
        // }
      }).catch(error => {
        console.log(error)
        return error
      });
    },


    // data posisi
    getPosisi() {
      axios.get(this.baseapi + "api/posisi?filter=id_proyek,=," + this.idProyek, {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        this.dataPosisi = response.data.data;
      }).catch(error => {
        console.log(error)
        return error
      });
    },


    getDetailTask(id, data) {
      axios.get(this.baseapi + "api/task/" + id, {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        this.detailTask = response.data.data;
        this.detailTaskOri = response.data.data;
        // if (this.detailTask.id_penanggungjawab != null) {
        //   const myArray = this.detailTask.id_penanggungjawab.split("||").map(Number);
        //   this.detailTask.id_penanggungjawab = myArray
        // }

        if (this.detailTask.start != null) {
          let myArray = []
          myArray.push(this.detailTask.start)
          myArray.push(this.detailTask.end)
          this.detailTask.waktu = myArray
        } else {
          this.detailTask.waktu = null
        }

        if (this.detailTask.filename === null) {
          this.detailTask.file = null
        } else {
          this.detailTask.file = this.detailTask.filename
        }

        if (this.detailTask.id_penanggungjawab[0] === null) {
          this.detailTask.id_penanggungjawab = []
          this.detailTask.nama_penanggungjawab = []
        }

        if (data === 'anggota') {
          this.$bvModal.show('modal-edit-task-anggota')
        } else {
          this.$bvModal.show('modal-edit-task')
        }
        console.log(this.detailTask)
      }).catch(error => {
        console.log(error)
        return error
      });
    },

    getVariable() {
      axios.get(this.baseapi + "api/variable_group", {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        this.dataVariable = response.data.data;
      }).catch(error => {
        console.log(error)
        return error
      });
    },

    getAbility() {
      axios.get(this.baseapi + "api/ability", {
        headers: {
          Authorization: `Bearer ` + this.user.accessToken
        }
      }).then(response => {
        this.dataAbility = response.data.data;
      }).catch(error => {
        console.log(error)
        return error
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="row mt-4">
              <!-- <div class="form-group row">
              <div class="col-sm-6">
                <b-button variant="success" v-b-modal.modal-tambah
                  ><i class="fas fa-plus"></i> Tambah</b-button
                >
              </div>
              <div class="col-sm-6 text-end">
              </div>
            </div> -->

              <div class="col-sm-6 col-md-6">
                <b-button variant="success" v-if="dataProyek.ability.tambah_kegiatan == true" size="sm" class="mb-2"
                  v-b-modal.modal-tambah><i class="fas fa-plus"></i> Add Task</b-button>
                <!-- <b-button variant="success" size="sm" class="mb-2 ml-2" v-b-modal.modal-anggota 
                  ><i class="fas fa-user-plus"></i> Anggota</b-button
                >

                <b-button variant="success" size="sm" class="mb-2 ml-2" v-b-modal.modal-posisi
                  ><i class="fas fa-user-plus"></i> Posisi</b-button
                > -->
              </div>
              <!-- <div class="col-sm-6 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div> -->
              <!-- Search -->
              <div class="col-sm-6 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="dataTask" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered" head-variant="light">

                <template #cell(anggota)="data">
                  <span style="display:none">{{ data }}</span>
                  <div class="col-md-12">
                    <div class="product-color">
                      <template v-if="data.item.nama_penanggungjawab[0] != ''">
                        <a href="#" class="active" v-for="row in data.item.nama_penanggungjawab.slice(0, 2)" :key="row">
                          <div class="avatar-xs align-self-center">
                            <span class="avatar-title rounded-circle bg-light text-body">{{ row.charAt(0) }}</span>
                          </div>
                        </a>
                      </template>
                      <a v-if="data.item.type != 'parent'" @click="getDetailTask(data.item.id, 'anggota')" type="button">
                        <div class="avatar-xs align-self-center">
                          <span class="avatar-title rounded-circle bg-light text-body">+</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </template>

                <template #cell(dependency)="data">
                  <div v-if="data.item.level == 0" style="max-width: 200px">
                    <b>{{ data.item.predecessors_task }}</b>
                  </div>
                  <div v-else style="max-width: 200px">
                    {{ data.item.predecessors_task }}
                  </div>
                </template>

                <template #cell(filename)="data">
                  <a v-if="data.item.filename != null" :href="data.item.filename" title="Download"><i
                      class="mdi mdi-download font-size-18"></i></a>
                </template>

                <template #cell(bobot)="data">
                  <div v-if="data.item.level == 0">
                    <b>{{ data.item.bobot }}</b>
                  </div>
                  <div v-else>
                    {{ data.item.bobot }}
                  </div>
                </template>

                <template #cell(nama_task)="data">
                  <div v-if="data.item.level == 0" style="max-width: 350px">
                    <b>{{ data.item.kode_task }} {{ data.item.nama_task }}</b>
                  </div>
                  <div v-else :style="{ 'margin-left': data.item.level + '0px', 'max-width': '350px' }">
                    <span>{{ data.item.kode_task }} {{ data.item.nama_task }} </span>
                  </div>
                </template>

                <template #cell(start)="data">
                  <!-- <span v-if="data.item.type == 'parent'">
                    <b>{{new Date(data.item.start).toLocaleDateString('id-ID', options)}} sd {{new Date(data.item.end).toLocaleDateString('id-ID', options)}}</b>
                  </span> -->
                  <span>
                    {{ format_date(data.item.start) }} - {{ format_date(data.item.end) }}
                  </span>
                  <b-progress :value="data.item.progres" :max="100" show-value height="1rem"
                    variant="success"></b-progress>
                </template>
                <template #cell(action)="data">
                  <a type="button" @click="getDetailTask(data.item.id)" data-toggle="tooltip" title="Edit"
                    class="mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                  <a type="button" @click="deleteTask(data.item.id)" title="Delete" class="text-danger"><i
                      class="mdi mdi-trash-can font-size-18"></i></a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal tambah -->
    <b-modal body-class="bg-white" id="modal-tambah" title="Add Task" cancel-title="Close" ok-title="Accept"
      cancel-variant="outline-secondary" size="lg" @show="resetModal" @hidden="resetModal">
      <form ref="form" data-vv-scope="form-1" @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Task Name" label-for="name" class="mb-3">
              <b-form-input v-validate="'required'" name="Nama Kegiatan"
                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.Nama Kegiatan') }" id="name"
                v-model="detailTask.nama_task" />
              <span class="text-danger" v-if="errors.has('form-1.Nama Kegiatan')">
                {{ errors.first('form-1.Nama Kegiatan') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Type" label-for="email" class="mb-3">
              <b-form-select v-model="detailTask.type" :options="optionsTask" name="tipe"
                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.tipe') }"></b-form-select>
              <span class="text-danger" v-if="errors.has('form-1.durasi')">
                {{ errors.first('form-1.durasi') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="7">
            <b-form-group label="Date Range" label-for="email" class="mb-3">
              <date-picker v-if="detailTask.type === 'parent'" disabled v-model="waktu" range append-to-body
                valueType="format" confirm></date-picker>
              <date-picker v-else v-model="waktu" range append-to-body valueType="format" confirm></date-picker>
            </b-form-group>
          </b-col>
          <template v-if="dataProyek.bobot === true">
            <b-col md="2" v-if="detailTask.type != 'parent'">
              <b-form-group label="Weight" label-for="email" class="mb-3">
                <b-form-input type="number" name="durasi" min="0" v-model="bobot" step="0.1" />
              </b-form-group>
            </b-col>
          </template>

          <b-col md="12">
            <b-form-group label="Task Description" label-for="email" class="mb-3">
              <b-form-textarea id="textarea-default" v-model="detailTask.deskripsi_task" name="Deskripsi Kegiatan"
                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.Deskripsi Kegiatan') }"></b-form-textarea>
              <span class="text-danger" v-if="errors.has('form-1.Deskripsi Kegiatan')">
                {{ errors.first('form-1.Deskripsi Kegiatan') }}
              </span>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Members" label-for="email" class="mb-3">
              <v-select multiple v-if="detailTask.type === 'parent'" disabled v-model="id_penanggungjawab"
                :reduce="user => user.id" label="nama_user" :options="dataMember" />
              <v-select multiple v-else v-model="id_penanggungjawab" :reduce="user => user.id" label="nama_user"
                :options="dataMember" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Dependency" label-for="email" class="mb-3">
              <!-- <v-select v-if="detailTask.type === 'parent'" disabled v-model="id_predecessors" :reduce="user => user.id" label="nama_task" :options="dataTask" /> -->
              <v-select v-model="id_predecessors" :reduce="user => user.id" label="nama_task" :options="dataTask" />
              <span class="text-danger" v-if="errors.has('durasi')">
                {{ errors.first('durasi') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Parent" label-for="email" class="mb-3">
              <!-- <v-select v-if="detailTask.type === 'parent'" disabled v-model="id_parent" :reduce="user => user.id" label="nama_task" :options="dataTask" /> -->
              <v-select v-model="id_parent" :reduce="user => user.id" label="nama_task" :options="dataTask" />
              <span class="text-danger" v-if="errors.has('durasi')">
                {{ errors.first('durasi') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="File" label-for="email" class="mb-3">
              <b-form-file v-model="file" placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."></b-form-file>
              <span class="text-danger" v-if="errors.has('durasi')">
                {{ errors.first('durasi') }}
              </span>
            </b-form-group>

          </b-col>
        </b-row>
      </form>


      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" style="float:right" @click="onSubmit">
            <i class="fas fa-save" />

            Save
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal body-class="bg-white" id="modal-edit-task" scrollable title="Edit Data" cancel-title="Close"
      ok-title="Accept" cancel-variant="outline-secondary" size="lg" @hidden="resetModal">
      <form ref="form" data-vv-scope="form-1" @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Task Name" label-for="name" class="mb-3">
              <b-form-input v-validate="'required'" name="Nama Kegiatan"
                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.Nama Kegiatan') }" id="name"
                v-model="detailTask.nama_task" />
              <span class="text-danger" v-if="errors.has('form-1.Nama Kegiatan')">
                {{ errors.first('form-1.Nama Kegiatan') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Type" label-for="email" class="mb-3">
              <b-form-select v-model="detailTask.type" :options="optionsTask" name="tipe"
                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.tipe') }"></b-form-select>
              <span class="text-danger" v-if="errors.has('form-1.durasi')">
                {{ errors.first('form-1.durasi') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="7">
            <b-form-group label="Date Range" label-for="email" class="mb-3">
              <date-picker v-if="detailTask.type === 'parent'" disabled v-model="waktu" range append-to-body
                valueType="format" confirm></date-picker>
              <date-picker v-else v-model="detailTask.waktu" range append-to-body valueType="format"
                confirm></date-picker>
            </b-form-group>
          </b-col>
          <template v-if="dataProyek.bobot === true">
            <b-col md="2" v-if="detailTask.type != 'parent'">
              <b-form-group label="Weight" label-for="email" class="mb-3">
                <b-form-input type="number" name="durasi" min="0" v-model="detailTask.bobot" step="0.1" />
              </b-form-group>
            </b-col>
          </template>

          <b-col md="12">
            <b-form-group label="Task Description" label-for="email" class="mb-3">
              <b-form-textarea id="textarea-default" v-model="detailTask.deskripsi_task" name="Deskripsi Kegiatan"
                :class="{ 'form-control': true, 'is-invalid': errors.has('form-1.Deskripsi Kegiatan') }"></b-form-textarea>
              <span class="text-danger" v-if="errors.has('form-1.Deskripsi Kegiatan')">
                {{ errors.first('form-1.Deskripsi Kegiatan') }}
              </span>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Members" label-for="email" class="mb-3">
              <v-select multiple v-if="detailTask.type === 'parent'" disabled v-model="detailTask.id_penanggungjawab"
                :reduce="user => user.id" label="nama_user" :options="dataMember" />
              <v-select multiple v-else v-model="detailTask.id_penanggungjawab" :reduce="user => user.id"
                label="nama_user" :options="dataMember" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Dependency" label-for="email" class="mb-3">
              <!-- <v-select v-if="detailTask.type === 'parent'" disabled v-model="id_predecessors" :reduce="user => user.id" label="nama_task" :options="dataTask" /> -->
              <v-select v-model="detailTask.id_predecessors" :reduce="user => user.id" label="nama_task"
                :options="dataTask" />
              <span class="text-danger" v-if="errors.has('durasi')">
                {{ errors.first('durasi') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Parent" label-for="email" class="mb-3">
              <!-- <v-select v-if="detailTask.type === 'parent'" disabled v-model="id_parent" :reduce="user => user.id" label="nama_task" :options="dataTask" /> -->
              <v-select v-model="detailTask.id_parent" :reduce="user => user.id" label="nama_task" :options="dataTask" />
              <span class="text-danger" v-if="errors.has('durasi')">
                {{ errors.first('durasi') }}
              </span>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="File" label-for="email" class="mb-3">
              <b-form-file v-model="detailTask.file" placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."></b-form-file>
              <span class="text-danger" v-if="errors.has('durasi')">
                {{ errors.first('durasi') }}
              </span>
            </b-form-group>

          </b-col>
        </b-row>
      </form>


      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" style="float:right" @click="editTask">
            <i class="fas fa-save" />

            Save
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal body-class="bg-white" id="modal-edit-task-anggota" title="Members" hide-footer cancel-title="Close"
      ok-title="Accept" cancel-variant="outline-secondary" size="md" @hidden="resetModal">
      <form ref="form" data-vv-scope="form-1" @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col md="12">
            <b-row>
              <b-col md="8">
                <v-select class="mt-1" v-model="id_penanggungjawab_tambah" :reduce="user => user.id" label="nama_user"
                  :options="dataMember" />
              </b-col>
              <b-col md="4">
                <b-button style="width: 100%;" variant="success" @click="tambahAnggota">Add Member</b-button>
              </b-col>
            </b-row>


            <hr>
            <b-form-group label="" label-for="email" class="mt-3">
              <b-form-input v-model="filterMember" type="search" placeholder="Search..."
                class="form-control form-control-sm mb-1"></b-form-input>

              <simplebar style="max-height: 345px" id="scrollElement">
                <ul class="list-unstyled chat-list">
                  <li class v-for="(data, index) in filteredList" :key="index">
                    <a href="javascript: void(0);">
                      <div class="media">

                        <div class="user-img mr-3">
                          <div class="avatar-xs align-self-center">
                            <span class="avatar-title rounded-circle bg-light text-body">{{ data.charAt(0) }}</span>
                          </div>
                        </div>
                        <div class="media-body overflow-hidden">
                          <h5 class="text-truncate font-size-14 mt-2">
                            {{ data }}
                          </h5>

                        </div>
                        <div class="font-size-11 mt-2 text-danger" @click="deleteAnggotaa(data)">Remove</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </simplebar>
            </b-form-group>
          </b-col>
        </b-row>
      </form>


      <template #modal-footer>
        <!-- <div class="w-100">
          <b-button variant="primary" style="float:right">
            <i class="fas fa-save" />

            Save
          </b-button>
        </div> -->
      </template>
    </b-modal>
  </Layout>
</template>